@import '../utils/mixins';
@import '../utils/variables';
@import '../utils/functions';


.beginning {
    &__charpter {
        .main {
            background-color: $background-color-tertiairy!important;
            &__consultation {
                &__background {
                    .main__consultation__background {
                        background-color: $background-color-tertiairy!important;
                    }
                }
            }    
        }
        &__begins {
            &__text {
                width: 50%!important;
            }
            img {
                width: 50%;
            }
        }
    }
}

.home {
    &__content {
        &__cover {
            &__about {
                &__image {
                    background-color: $background-color-tertiairy;
                    display: flex;
                }
            }
        }
    }
}

.about {
    &__page {
        &__mission {
            &__title {
                padding-top: 15%!important;
            }
        }
    }
    .main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .logo {
            width: 100%;
            padding: 20% 40% 10% 40%;        
        }
        &__consultation {
            &__background {
                background-color: $background-color-primary;
            }
        }
        &__Consultation {
            &__container {
                top: 6.5rem;
            }
        }      
    }
    &__toward {
        &__container {
            padding: 0;
            width: 100%;
        }
        &__description {
            padding: 2rem 2rem;
            width: 50%;
            h2 {
                font-size: 45px;
            }
            h3 {
                font-size: 30px;
            }
            &__sub {
                width: 35%!important;
                padding: 5rem 2rem;
            }
        }
        &__image {
            img {
                padding: 0!important;
                object-position: left;
            }
            &__sub {
                width: 65%!important;
                img {
                    padding: 10rem 0 10rem 0 !important;
                }
            } 
        }
    }
    &__inspiring {
        &__voices {
            &__container {
                padding: 15rem 0 5rem 0;
                background-color: $background-color-second!important;
            }
            &__text {
                padding: 2rem;
                width: 50%!important;
                h1 {
                    font-size: 45px;
                    padding-bottom: 3rem!important;
                    color: $text-color-second;
                }
                p {
                    font-size: 30px;
                    padding-bottom: 2rem;
                    color: $text-color-second;
                }
            }
            &__image {
                display: flex;
                align-self: center;
                width: 50%!important;
            }
        }
    }
}

//=======================================  Laptop ======================================//
@include Laptop {
    #about__page {
        .toward {
            &__section {
                &__laptop {
                    padding: 15% 0 0 0;
                }
            }
        }
    }
    .about__page__mission__title {
        width: 100%;
        p {
            padding: 0 2%;
        }   
    }
}

//=======================================  Big Tablet ======================================//
@include bigTablet {
    #about {
        &__page {
            padding: 30% 0 0 0;
            #inspiring__voices__section {
                .about {
                    &__inspiring {
                        &__voices {
                            &__image {
                               height: 100vh!important; 
                            }
                        }
                    }
                }
            }
        }
    }
    #begining {
        &__cover {
            &__section {
                .program {
                    &__reconciliation {
                        &__text {
                            h1 {
                                font-size: $button-font-size-big!important;
                                padding: 0rem!important;
                            }
                            p {
                                font-size: $text-size-very-very-small;
                            }
                        }
                    }
                }
            }
        }
    }
    #toward {
        &__cover {
            &__section {
                padding: 30% 0 0 0;
                .about__toward {
                    &__description {
                        h2 {
                            font-size: $button-font-size-big!important;
                        }
                        h3 {
                            font-size: $card-font-size-title-middle;
                        }
                        &__sub {
                            width: 50%!important;
                        }
                    }
                    &__image {
                        img {
                            object-position: 30%;
                        }
                        &__sub {
                            width: 55%!important;
                        }
                    }
                } 
            }
        }
    }
    .frustation {
        .about {
            &__page{
                &__mission {
                    &__title {
                        padding: 25% 0 0 0!important;
                    }
                }
            }
        }
    } 

}

//=======================================  Mini Tablet ======================================//
@include tablet {
    #about {
        &__page {
            padding: 30% 0 0 0;
            #inspiring__voices__section {
                .about {
                    &__inspiring {
                        &__voices {
                            &__image {
                               height: 100vh!important; 
                               object-position: 45%!important;
                            }
                        }
                    }
                }
            }
        }
    }
    #begining {
        &__cover {
            &__section {
                .program {
                    &__reconciliation {
                        &__text {
                            h1 {
                                font-size: $button-font-size-big!important;
                                padding: 0rem!important;
                            }
                            p {
                                font-size: $text-size-very-very-small;
                            }
                        }
                    }
                }
            }
        }
    }
    #toward {
        &__cover {
            &__section {
                padding: 30% 0 0 0;
                .about__toward {
                    &__description {
                        h2 {
                            font-size: $button-font-size-big!important;
                        }
                        h3 {
                            font-size: $card-font-size-title-middle;
                        }
                        &__sub {
                            width: 50%!important;
                        }
                    }
                    &__image {
                        img {
                            object-position: 30%;
                        }
                        &__sub {
                            width: 55%!important;
                        }
                    }
                } 
            }
        }
    }
}

//=======================================  Mobile ======================================//
@include mobile {
    .home {
        &__section {
            &__mission {
                &__content {
                    p {
                        font-size: 25px!important;
                    }
                }
            }
        }
        &__content {
            &__cover {
                &__about {
                    &__image {
                        img {
                            width: 100% !important;
                            height: 100vh !important;
                            object-fit: cover !important;
                            object-position: 70%;
                        }
                    }
                }
            }
        }
    }

    .about {
        &__page {
            &__mission {
                &__title {
                    padding-top: 60%!important;
                    padding-left: 1%!important;
                    padding-right: 1%!important;
                }
            }
        }
        &__content {
            &__cover {
                &__about {
                    &__image {
                        &__header {
                            img {
                                height: 60vh!important;
                                object-fit: cover;
                                object-fit: 50%!important;
                            }
                        
                        }
                        &__footer {
                            height: 100vh!important;
                            object-fit: cover;
                                object-position: 70%;
                        }
                    }
                }
            }
        }
        &__toward {
            &__cover {
                &__section {
                    padding-bottom: 2rem 0;
                }
            }
            &__description {
                width: 100vw;
                &__sub {
                    width: 100vw!important;
                    padding: 5rem 2rem 0 2rem;
                }
                h2 {
                    font-size: $card-font-size-title;
                    padding-bottom: 0;
                }
                h3 {
                    font-size: $card-font-size-title-big;
                }
            }
            &__image {
                width: 100vw!important;
                height: 50vh!important;
                object-fit: cover;
                    &__sub {
                        img {
                            padding: 0;
                            width: 100vw!important;
                            height: 70vh!important;
                            padding: 20% 0!important;
                        }
                    }
                }
            }
            &__container {
                justify-content: center;
                &__sub {
                    flex-direction: column-reverse!important;
                    padding: 0 0 20% 0!important;
                }       
            }
            
            
        .main {
            &__Consultation {
                &__container {
                    top: 8rem;
                }
            }
            .home__sub__testimony__about {
                flex-direction: column-reverse!important;
            }
            .logo {
                width: 100%;
                padding: 120% 25% 50% 25% !important;
            }
        }
        &__inspiring {
            &__voices {
                &__container {
                    &__blue {
                        padding: 20rem 0 5rem 0!important;
                        &__reverse {
                            flex-direction: column-reverse!important;
                        }
                    }
                }
                &__text {
                    width: 100vw!important;
                }
                &__image {
                    width: 100vw!important;
                    height: 50vh!important;
                    object-fit: cover;
                }
            }
        }
    }

    .beginning {
        &__charpter {
            &__begins {
                flex-direction: column-reverse;
                &__text {
                    width: 100vw!important;
                }
                &__image {
                    width: 100vw!important;
                    height: 50vh!important;
                    object-fit: cover;
                    img {
                        width: 100vw!important;
                        height: 50vh!important;
                        object-fit: cover;

                    }
                }
                &__reverse {
                    flex-direction: column-reverse!important;
                }
            }
        }
    }

    
    
}