@import '../utils/mixins';
@import '../utils/variables';
@import '../utils/functions';

.section {
    background-color: $background-color-primary;
}

.main{
    &__consultation {
        &__background {
            background-color: $background-color-second;
            height: 13rem;
        }
    }
    &__Consultation{
        border-radius: 1rem;
        display: flex;
        justify-content: center;
        &__container{
            position: relative;
            top: 2.5rem;
            width: 973px;;
            height: 523px;
            display: flex;
            border-radius: 1.5rem;
            box-shadow: 0 2px 2px 2px $background-color-for-input-secondary;
            background-color: $background-color-primary;
            .description{
                width: 60%;
                height: 100%;
                text-align: center;
                justify-content: space-evenly;
                display: flex;
                flex-direction: column;
                h2{
                    color: $text-color-primary;
                    font-weight: 900;
                }
                &__input{
                    display: flex;
                    justify-content: space-evenly;
                    margin: 1rem;
                    .infos{
                        display: grid;
                        gap: 1rem;
                        input{
                            height: 3.5rem;
                            width: 14rem;
                            color: $input-color-primary;
                            background: $input-background-color-secondary;
                            border: none;
                            text-align: center;
                            &:hover{
                                background-color: $liBackgroundColorHoverPrimary;
                                color: $liBackgroundColorHoverSecondary;
                                box-shadow: 0 3px 3px 3px $background-color-for-input-secondary;
                            }
                        }
                    }
                    .message{
                        display: grid;
                        gap: 1rem;
                        button{
                            @include displayFlexCenter;
                            width: 10rem;
                            height: 2.5rem;
                            margin-left: 20px;
                            background: $button-background-color-primary;
                            color: $button-background-color-primary-on-hover;
                            border: none;
                            border-radius: 25px;
                            font-weight: 600;
                        }
                        input{
                            padding-bottom: 5rem;
                            padding-left: 2rem;
                            height: 8.5rem;
                            width: 14rem;
                            color: $input-color-primary;
                            background: $input-background-color-secondary;
                            border: none;
                            &:hover{
                                background-color: $liBackgroundColorHoverPrimary;
                                color: $liBackgroundColorHoverSecondary;
                                box-shadow: 0 3px 3px 3px $background-color-for-input-secondary;
                            }
                        }
                    }
                }
                .social{
                    @include displayFlexCenter;
                    gap: 2rem;
                    a{
                        img{
                            width: 2rem;
                            height: 2rem;
                        }
                    }
                }
                
            }
            .doctor{
                width: 40%;
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 0rem 1rem 1rem 0rem;
                    object-fit: cover;
                }
            }
            
        }
    }
}   

.home {
    &__section {
        &__testimonial {
            h1 {
                padding: 4rem 0 1rem 0!important;
                font-size: $text-size-big;
            }
        }
        &__program {
            &__content {
                height: 1024px;
            }
        }
    }
}

//================================= Large Desktop =================================//
@include largeDesktop {
    .testimonial {
        .main {
            &__consultation {
                &__background {
                    height: 15vh!important;
                }
            }
        }
    }
}

//================================= Big =================================//
@include bigTablet {
    .main {
        &__Consultation {
            &__container {
                width: 80%;
                gap: 0.5rem;
                button {
                    width: 9rem!important;
                }
            }
        }
    }
    .description {
        &__input {
            gap: 0.5rem;
            padding-left: 1rem!important;
        }
    }
    .negative {
        .main__Consultation__container {
            top: 10rem!important;
        }
    }
}

//================================= Mini Tablet =================================//
@include tablet {
    .main {
        &__Consultation {
            &__container {
                width: 80%;
                gap: 0.4rem;
                button {
                    width: 8rem!important;
                }
                .description {
                    h2 {
                        font-weight: 500;
                    }
                }
            }
        }
    }
    .description {
        &__input {
            gap: 0.4rem;
            padding-left: 0.4rem!important;
        }
    }
}   

//=======================================  Mini Mobile ======================================//
@include miniMobile {
    .main__Consultation__container {
        .description__input {
            .message {
                button {
                    width: 6.5rem;
                }
            }
        }
    } 
}