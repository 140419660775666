@import '../utils/mixins';
@import '../utils/variables';
@import '../utils/functions';

#ourteam {
    .services__program__card__background {
        background-color: $background-color-primary;
    }
    .about__toward__image__sub {
        width: 100vw!important;
        background-color: $background-color-firth;
        img {
            width: 100%!important;
            object-fit: cover!important;
            padding: 0!important;
        }
    }
}

//===================================== Laptop =====================================//
@include Laptop {
    #ourteam .profile {
        &__card {
            &__style {
                gap: 2rem;
                width: 200px;
            }
        }
        .services {
            &__program {
                &__card {
                    &__background {
                        padding: 15% 0 0 0;   
                    }
                }
            }
        }
    }

    .about {
        .main {
            .logo {
                padding: 30% 40% 10% 40%
            }
        }
    }
}

//=======================================  Big Tablet ======================================//
@include bigTablet {
    #ourteam {
        padding: 30% 0 0 0;
        .profile {
            &__card {
                &__style {
                    &__container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                    }
                    &__line {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 2rem;
                    }
                }
            }
        }
        .about {
            .main {
                .logo {
                    padding: 40% 40% 10% 40%;
                }
            }
        }
    }
}

//=======================================  Mini Tablet ======================================//
@include tablet {
    #ourteam {
        padding: 30% 0 0 0;
        .profile {
            &__card {
                &__style {
                    &__container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                    }
                    &__line {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 2rem;
                    }
                }
            }
        }
        .about {
            .main {
                .logo {
                    padding: 40% 40% 10% 40%;
                }
            }
        }
    }  
}