//=========================== Utils =========================//
@import '../utils/mixins';
@import '../utils/variables';
@import '../utils/functions';
//=========================== Font =========================//
//Montserrat
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
//Roboto
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
//Ubuntu
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
//Poppins
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
//Cantarell
@import url('https://fonts.googleapis.com/css2?family=Cantarell:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
//Noto Sans
@import url('https://fonts.googleapis.com/css2?family=Cantarell:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
//=========================== End Font =========================//

.media{
    width: 2rem;
}
.sucess{
    color: $background-color-second;
}
.error{
    color: $text-error-color-secondary;
}

*{
    margin: 0!important;
    box-sizing: border-box;
    &:before, 
    &:after {
        box-sizing: border-box;
    }
}


body {
    font-family: "Montserrat", Roboto, Ubuntu, Canela, Poppins, Pathway-Extreme, -apple-system, Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont, Oxygen-Sans, Arial, Helvetica, sans-serif;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Montserrat", Roboto, Ubuntu, Arial, Helvetica, sans-serif;
}

p {
    font-family: "Ubuntu", Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, Arial, Helvetica, sans-serif;
    font-size: 20px;
}

.page{
    &__title{
        &__text {
            font-weight: 100!important;
        }
    }
}

.mobile__menu {
    display: flex;
    gap: 10px;
    justify-self: center;
    display: block;
}

a, Link, input, .media, button, .hamburger {
    cursor: pointer;
}

a {
    text-decoration: none;
    font-size: 25px;
}
.link{
    color: $text-color-second !important; 
}
.link {
    position: relative;
    text-decoration: none; 
    color: $text-color-second; 
  }
  
  .link::before {
    content: '';
    position: absolute;
    bottom: 3px; 
    left: 0;
    right: 0;
    height: 3px; 
    background-color: $text-color-second; 
    transform: scaleX(0); 
    transition: transform 0.3s ease; 
  }
  
  .link:hover::before {
    transform: scaleX(1); 
  }

  .spinner-wrapper{
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $text-color-second;
    z-index: 1000;
    img{
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100px;
        width: 100px;
        margin: 0px auto;
    }
  }

.hidden {
    display: none;
}


 /* ======================================== Big Tablet ===================================== */
@include mobile {
    width: 100vw!important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}



