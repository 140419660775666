@import '../utils/mixins';
@import '../utils/variables';
@import '../utils/functions';

    nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 150px;
        width: 100%;
        top: 5px;
        background-color: $background-color-primary;
        .logo{
            height: 120px;
            display: flex;
            justify-content: center;
            //margin-top: 3%;
            img{
                height: 110px;
                margin-left: 60px;
            }
        }
        .menu {
            ul {
                display: flex;
                margin-right: 60px;
                font-size: 25px;
                gap: 50px;
                a {
                    position: relative;
                    color: $liBackgroundColorHoverSecondary;
                    text-decoration: none!important;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        background-color: $liBackgroundColorHoverSecondary;
                        bottom: 0;
                        left: 0;
                        transform: scaleX(0);
                        transform-origin: bottom left;
                        transition: transform 0.3s ease !important;
                        }
        
                    &:hover::after {
                        transform: scaleX(1)!important;
                    }
                }
            }
        }
        a {
            align-self: center;
        }
        .nav {
            &__user {
                &__container {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    object-fit: cover;
                    height: 100%;
                }
                &__img {
                    width: 100%;
                    border-radius: 50%;
                    height: auto;

                }
            }
        }
    }
    
    .mobile {
        &__menu {
            display: none;
        }
    }

//=======================================  Mobile ======================================//
@include mobile {
    nav {
        height: 75px!important;
        .logo {
            width: 30%;
            height: 500px!important;
            align-items: center!important;
            img {
                width: 50%!important;
                height: auto;
            }
        }
        .hamburger {
            width: 15%;
            &__text {
                align-self: center;
                font-size: 20px;
                padding-bottom: 10px;
            }
        }
    }

    .mobile {
        &__menu {
            display: flex!important;
        }
    }
}

//===================================== Tablet =====================================//
@include bigTablet {
header {
    padding: 0!important;
}
nav {
    height: 100px!important;  
    display: flex;
    height: 100px;
    padding: 0!important;
    .logo {
        width: 10%;
        justify-content: flex-start;
        z-index: 9999;
        height: 100px;
        align-items: center;
        padding: 5%;
        img {
            height: 50px;
        }
    }
    ul {
        padding: 0 2% 0 0;
        height: 100%;
        justify-content: flex-end;
        a {
            font-size: $button-font-very-small;
        }
    }
}
.navigation {
    display: block;
    width: 100%;
}
}

//===================================== Laptop =====================================//
@include Laptop {
    nav {
        height: 10%;
        padding: 0 0 0 0!important;
        img {
            height: 100%;
            padding: 1rem;
        }
        ul {
            gap: 1rem;
        }
        a {
            font-size: $navbar-link-font-size-small;
        }
    }
}