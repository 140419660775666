@import '../utils/mixins';
@import '../utils/variables';
@import '../utils/functions';

.home{
    &__banner {
        width: 100%;
        height: 100vh;
        background-image: linear-gradient(rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40));
        background-position: center;
        background-size: center;
    }
    &__content {
        width: 55%;
        position: absolute;
        color: $text-color-second;
        top: 70%;
        padding: 0 2%;
        transform: translateY(-50%);
        text-align: center;
        &__card {
            &__title {
                display: flex;
                justify-content: center;
                text-align: center;
                color: $text-color-second;
                gap: 3rem!important;
                h3 {
                    font-size: $card-font-size-title-big;
                    font-weight: bold;
                }
            }
            &__text {
                display: flex;
                justify-content: center;
                text-align: center;
                color: $text-color-second;
                gap: 3rem!important;
                h2 {
                    font-size: $card-font-size-title!important;
                    font-weight: bold!important;
                }
                p {
                    font-size: $card-font-size-text-small;
                }
            }
            &__header {
                padding: 4rem 0 0 0;
            }
            &__slogan {
                p {
                    font-size: $card-font-size-title-big;
                }
            }
        }
        &__button {
            &__blue {
                background-color: $background-color-second;
                color: $background-color-primary;
                border: 1px solid $background-color-second;
                cursor: pointer;
                &__about {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding: 1rem 0 6rem 0;
                    &__button {
                        padding-bottom: 6rem!important;
                    }
                }
                &__cover {
                    padding: 1rem;
                }
                &__our {
                    &__team {
                        border: 1px solid $background-color-second;
                    }
                }
            }
            &__white {
                background-color: $button-background-color-tertiairy;
                color: $button-text-color-tertiairy;
                border: $button-border-color-second;
                cursor: pointer;
                &__cover {
                    padding: 1rem;
                }
            }
        }
        &__image {
            top: 10%;
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: -1;
            width: 100vw;
        }
        &__title {
            h1 {
                font-size: $cover_font_size_title;
                font-weight: bold;
            }
        }
        &__slogan {
            p {
                font-size: $cover_font_size_text;
                font-weight: bold;
            }
        }
        &__cover {
            &__about {
                &__image {
                    width: 100vw!important;
                    background-color: $background-color-second;
                    img {
                        width: 100%!important;
                        height: 100%!important;
                        object-fit: cover!important;
                    }
                }
            }
        }
    }
    &__intro{
        background: url(https://res.cloudinary.com/dhxqidnfl/image/upload/v1705882433/young-woman-attending-group-therapy-looking-camera_qziqef.jpg);
        background-size: cover;
        background-position: center;
        flex-direction: column;
        display: flex;
        height: fit-content;
        margin: 0; 
        height: 100%;
        &__bigmage{
            background-color: black;
            height: 100%;
            opacity: 0.3;
        }  
    }
    &__section {
        &__mission {
            padding: 0 0 0 0;
            display: flex;
            justify-content: center;
            text-align: center;
            padding-bottom: 2rem;        
            h1, p {
                font-size: $text-size-big!important;
                padding-bottom: 2rem;
            }
            h1 {
                font-weight: bold!important;
            }
        }
        &__testimonial {
            background-color: $background-color-second;
            display: flex;
            flex-direction: column;
            h1 {
                font-size: $text-size-big!important;
                color: $text-color-second!important;
                text-align: center;
                padding: 3rem 0 1rem 0!important;
            }
            .liatestimons{
                display: flex;
                flex-direction: row!important;
                justify-content: space-evenly;
                &__image{
                    display: grid;
                    gap: 1rem;
                    font-weight: 400;
                    font-style: italic;
                    padding: 1rem;
                    color: $text-color-second;
                    &__single{
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        h4 {
                            font-size: $card-font-size-title-middle;
                            font-weight: bold;                        
                        }
                        p {
                            font-size: $card-font-size-title-middle;
                        }
                        img{
                            width: 100px;
                            height: 100px;
                            border-radius: 100%;
                        }
                    }
                    &:hover{
                        background-color: $background-color-primary;
                        color: $button-background-color-primary;
                        border-radius: 1rem;
                        box-shadow: 0 1px 1px 1px $background-color-for-input-secondary;
                    }
                        
                }
                
            }
        }
    }
    &__sub {
        &__testimony {
            display: flex!important;
            &__description {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                width: 50%!important;
                text-align: center;
                padding: 17rem 2rem;
                h2, h3 {
                    padding-bottom: 6rem;
                }
                img {
                    padding-top: 6rem;
                    width: 10rem;
                }
            }
            &__image {
                width: 50%!important;
                img {
                    width: 100%!important;
                    height: 100%!important;
                    object-fit: cover!important;
                    padding: 17rem 0 10rem 0!important;
                }
            }
        }
    }
    
    
    .main{
        &__team {
            display: flex!important;
            &__description {
                width: 50%!important;
            }
            &__image {
                width: 50%!important;
                img {
                    width: 100%!important;
                    height: 80%!important;
                    object-fit: cover!important;
                    padding: 17rem 0 10rem 0!important;
                }
            }
        }
        background-color: $background-color-second;
        &__header{
            height: 15rem;
            h1{
                @include displayFlexCenter;
                padding: 4rem;
                color: $text-color-second;
                font-weight: bold;
                font-size: 2rem;
            }
        }
       
    }
    

    .top{
        font-family: Arial, Helvetica, sans-serif;
        width: 100%;
        height: 100dvh;
        margin: 0;
        nav{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 150px;
            width: 100%;
            top: 5px;
            background-color: $background-color-primary;
            .logo{
                height: 120px;
                display: flex;
                justify-content: center;
                //margin-top: 3%;
                img{
                    height: 110px;
                    margin-left: 60px;
                }
            }
            .menu {
                ul {
                    display: flex;
                    margin-right: 60px;
                    font-size: 25px;
                    gap: 50px;
                    a {
                        position: relative;
                        color: $liBackgroundColorHoverSecondary;
                        text-decoration: none!important;
                        &::after {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 2px;
                            background-color: $liBackgroundColorHoverSecondary;
                            bottom: 0;
                            left: 0;
                            transform: scaleX(0);
                            transform-origin: bottom left;
                            transition: transform 0.3s ease !important;
                            }
            
                        &:hover::after {
                            transform: scaleX(1)!important;
                        }
                    }
                }
            }
        }
        .title-image{
            display: flex;
                height: 0;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                position: relative;
                bottom: 18rem;
                left: 2rem;
                padding-right: 50%;
                z-index: 999;
            h1{
                display: flex;
                color: $text-color-second;
                text-align: center;
                position: relative;
                bottom: 4rem;
                font-weight: bold;
                font-size: 4rem;
            }
            p{
                display: flex;
                font-weight: bold;
                font-size: 2rem;
                text-align: center;
                color: $text-color-second;
                position: relative;
                bottom: 2rem;
            }
            
                button{
                    background: $button-background-color-primary; 
                    border: none;
                    color: $button-text-color-primary;
                    font-weight: bold;
                    font-size: 1.2rem;
                    padding: 2.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: bolder;
                    font-size: 1.4rem;
                    
                }
        }
        .cards{
            width: 100%;
            height: 513px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            text-align: center;
            background-color: $background-color-primary;
            .card{
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                width: 24%;
                height: 96%;
                h2{
                    font-weight: bold;
                }
                img{
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                }
                &:hover{
                    box-shadow: 0 3px 3px 3px $background-color-for-input-secondary;

                }
            }
        }
        .activities{
            background: $background-color-second;
            color: $button-text-color-primary;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 0;
            height: 900px;
            h2{
                font-size: 30px;
                padding-top: 40px;
            }
            p{
                font-size: 25px;
                padding-top: 30px;
            }
            .cards2{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 3%;
                height: fit-content;
                .card2{
                    width: 300px;
                    height: 300px;
                    gap: 8px;
                    padding-top: 40px;
                    img{
                        width: 300px;
                        height: 300px;
                        object-fit: cover;
                    }
                    h3{
                        font-size: 30px;
                        height: 7rem;
                        padding: 10px 0;
                    }
                    p{
                        font-size: medium;
                        height: 5rem;
                        padding: 1rem 0;
                    }
                    button{
                        margin-top: 2rem;
                    }
                  
                }
            }
        }
    }
    .program{
        &__reconciliation{
            width: 100%;
            background-color: $background-color-tertiairy;
            display: flex;
            &__image{
                width: 70%;
                img{
                    width: 100%;
                    height: 100%;
                }
                
            }
            &__text{
                width: 30%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 2rem;
                padding: 2rem;
                h1,h3{
                    text-align: center;
                    font-weight: bold;
                }
                h1{
                    font-size: $text-size-middle;
                    color: $text-color-primary;
                }

                h3 {
                    font-size: $text-size-very-very-small;
                }

                button{
                    @include buttonbig;
                    color: $button-text-color-primary;
                    background: transparent;
                    border: 1px solid $button-text-color-primary;
                    border-radius: 1rem;
                    font-weight: bold;
                }
             
                button:before{
                    background-color: $button-text-color-primary;
                    color: $background-color-tertiairy;
                }
                button:hover{
                    color: $background-color-tertiairy;
                }
            }
        }
        
    }
  .testimonial{
    
        .main{
            &__team {
                display: flex;
                &__description {
                    width: 50%;
                }
                &__image {
                    width: 50%;
                    img {
                        width: 100%;
                        height: 80%;
                        object-fit: cover;
                        padding: 15rem 0 10rem 0;
                    }
                }
            }
            background-color: $background-color-second;
            &__header{
                height: 15rem;
                h1{
                    @include displayFlexCenter;
                    padding: 4rem;
                    color: $text-color-second;
                    font-weight: bold;
                    font-size: 2rem;
                }
            }
           
        }
    }

    .mission{
        &-frist{
            @include displayFlexCenter;
            background-color:$background-color-primary;
            flex-direction: column;
            text-align: center;
            h1{
                text-align: center;
                font-size: 3.3rem;
                font-family: Arial;
            }
            p{
                text-align: center;
                font-size: 3.3rem;
                font-family:  Helvetica;
                font-weight: 100;
                letter-spacing: 1px;
                padding: 2rem 0;
            }
            button{
                @include buttonbig;
                border-radius: 1px;
                font-weight: 600;
                border-radius: 1rem;
                color:$background-color-primary ;
                background-color:$background-color-second;
                border-color:transparent;
                &:hover{
                    color: $text-color-second;
                }
            }
             
        }
        &-second{
            img{
                width:100%;
                height: 48rem;
                object-fit: cover;
            }
        } 
    }
}


//================================= Large Desktop =================================//
@include largeDesktop {
    .home {
        &__sub {
            &__testimony {
                padding: 10% 0 0 0;
            }
        }
    }
}

//================================= Laptop =================================//
@include Laptop {
    .home__section__mission {
        h1, p {
            padding-bottom: 3rem!important;
        }
    } 

    
}

//================================= Big =================================//
@include bigTablet {
    .cover {
        .home__content {
            width: 60%;
            &__image {
                height: 100vh;
                object-fit: cover;
                object-position: -15rem 0rem;
                top: 5%;
            }
            &__text {
                h1 {
                    font-size: $card-font-size-title;
                }
            }
            &__title {
                h1 {
                    font-size: $card-font-size-title;
                }
            }
        }
    }.home__section__testimonial .liatestimons__image
    .home {
        &__section {
            &__cover {
                &__end {
                    .card {
                        &__section {
                            &__icons {
                                top: -9px!important;
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                            }
                        }
                    }
                }
            }
        }
        &__content {
            &__button {
                &__blue {
                    &__cover {
                        padding: 0.2rem;
                    }
                }
            }
            &__slogan {
                padding: 1rem 0;
            }
        }
    }
    .program {
        &__width {
            &__laptop {
                &__text {
                    padding: 0;
                    width: 50%!important;
                    h1 {
                        font-size: $card-font-size-title-big!important;
                        padding: 0!important;
                    }
                }
            }
        }
        &__social {
            &__explore {
                &__card {
                    width: 300px!important;
                    height: 200px!important;
                    img{
                        width: 300px!important;
                        height: 200px!important;
                    }
                }
            }
        }
    }
    .program__reconciliation__image {
        width: 60%!important;
        height: 45vh!important;
    }

    .liatestimons {
        p, h4 {
            font-size: $button-font-very-small!important;
        }
    }
    .home__section__mission h1, 
    .home__section__mission p {
        font-size: $text-size-middle!important;
    }
    #toward__cover__section {
        .home__sub__testimony__description, 
        .home__sub__testimony__description {
            h2 {
                font-size: $button-font-size-big!important;
            }
            h3 {
                font-size: $card-font-size-title-middle;
            }
        }
    }
}

//================================= Mini Tablet =================================//
@include tablet {
    .cover {
        .home__content {
            top: 80%;
            width: 60%;
            &__image {
                height: 100vh;
                object-fit: cover;
                object-position: -15rem 0rem;
                top: 10%;
            }
            &__text {
                h1 {
                    font-size: $card-font-size-title;
                }
            }
            &__title {
                h1 {
                    font-size: $card-font-size-title;
                }
            }
        }
    }.home__section__testimonial .liatestimons__image
    .home {
        &__section {
            &__cover {
                &__end {
                    .card {
                        &__section {
                            &__icons {
                                top: -9px!important;
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                            }
                        }
                    }
                }
            }
        }
        &__content {
            &__button {
                &__blue {
                    &__cover {
                        padding: 0.3rem!important;
                    }
                }
            }
            &__slogan {
                padding: 1rem 0;
            }
        }
    }
    .program {
        &__width {
            &__laptop {
                &__text {
                    padding: 0;
                    width: 40%!important;
                    h1 {
                        font-size: $card-font-size-title-big!important;
                        padding: 0!important;
                    }
                }
            }
        }
        &__social {
            &__explore {
                &__card {
                    width: 300px!important;
                    height: 200px!important;
                    img{
                        width: 300px!important;
                        height: 200px!important;
                    }
                }
            }
        }
    }
    .program__reconciliation__image {
        width: 60%!important;
        height: 50vh!important;
        object-position: -3rem;
    }

    .liatestimons {
        p, h4 {
            font-size: $button-font-very-small!important;
        }
    }
    #toward__cover__section {
        .home__sub__testimony__description, 
        .home__sub__testimony__description {
            h2 {
                font-size: $button-font-size-big!important;
            }
            h3 {
                font-size: $card-font-size-title-middle;
            }
        }
    }
}


//================================= Mobile =================================//
@include mobile{
    
        .program{
           /*  &__reconciliation{
                width: 100rem;
                display: flex;
                flex-direction: column !important;
                padding-left: 14rem;
                
            } */

            &__social{
                &__explore{
                    display: flex!important;
                    flex-direction: column!important;
                    width: 100%;
                }
            }
        }
        .liatestimons {
            display: contents!important;
        }

        .main{
            &__Consultation {
                &__container {
                    width: 90vw;
                    .description {
                        display: flex;
                        align-items: center;
                        h2 {
                            font-size: $card-font-size-title-middle!important;
                        }
                        &__input {
                            display: flex;
                            width: 90%;
                            flex-direction: column;
                            .infos {
                                padding-bottom: 1rem;
                                input {
                                    height: 1.5rem;
                                }
                            }
                            .message {
                                input {
                                    height: 5rem;
                                    text-align: center;
                                    padding: 0;
                                }
                            }
                        }
                        .social {
                            a {
                                width: 0.5rem;
                                img {
                                    width: 1rem;
                                    height: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        .home {
            &__sub {
                &__testimony {
                    flex-direction: column;
                    justify-content: center;
                    &__description {
                        width: 100%!important;
                        padding: 70% 5% 5% 3%;
                        gap: 3rem;
                        h2, h3, .logo {
                            padding: 0;
                            font-size: $card-font-size-title-middle;
                        }                    
                    }
                    &__image {
                        width: 100vw!important;
                        img {
                            padding: 2rem 0!important;
                        }
                    }
                }
            }
        }

        .home__sub__testimony__description__new {
            padding: 80% 5% 5% 3%!important;
            margin: 40% 0!important; 
            .logo {
                padding-top: 0!important;
            }
        }

        .home__sub__testimony__description {
            img {
                padding-top: 2rem;
            }
        }

        .home {
            &__content {
                &__card {
                    &__text {
                        padding: 2%;
                    }
                }
            }
        }
        .home__content__cover__about__image__mobile{
            img {
                width: 100% !important;
                height: 100vh !important;
                object-fit: cover !important;
                object-position: 35%!important;
            } 
        }

}

@include min_aspect_ratio_16_9 {
    .home {
        &__banner {
            &__content {
                &__image {
                    width: 100vw;
                    height: auto;
                }
            }
        }
    }
}
