@import '../utils/mixins';
@import '../utils/variables';
@import '../utils/functions';

button{
    background: transparent;
    border-radius: 1rem;
    border: 1px solid $button-text-color-primary;
    color: $button-text-color-primary;
    font-weight: bold;
    font-size: $button-font-size-big;
    display: inline-table;
    z-index: 2;
    transition: all ease 1s;
    position: relative;
    width: 250px;
    height: 100px;
    &__programs {
        width: 250px!important;
        height: 100px!important;
    }
}

.button {
    &__our {
        &__team {
            border: 1px solid $button-text-color-tertiairy!important;
            background-color: $button-background-color-primary!important;
            color: $button-text-color-primary!important;
        }
    }
    &__explore {
        width: 200px !important;
        height: 50px !important;
        border: 1px solid $button-border-color-second!important;
        background-color: $button-background-color-tertiairy!important;
        color: $button-text-color-tertiairy!important;
        font-size: $cover_font_size_text;
    }
    &__footer {
        height: 50px !important;
    }
}

.button__explore:before {
    background-color: $button-background-color-primary!important;
    color: $button-text-color-primary!important;
}

.button__explore:hover {
    color: $button-text-color-primary!important;
}

.button__our__team:before {
    color: $button-text-color-tertiairy!important;
}

.button__our__team:hover {
    color: $button-text-color-tertiairy!important;
}

button:before{
    content: '';
    position: absolute;
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $button-text-color-primary;
    color: $background-color-second;
    border-radius: 1rem;
    z-index: -1;
    transition: all ease 1s;
} 


button:hover:before{
    width: 100%;
} 
button:hover{
    color: $background-color-second;
} 


//=======================================  Mobile ======================================//
@include mobile {
    button{
        width: 170px!important;
        height: 50px!important;
        font-size: $button-font-very-small;
        padding: 0!important;
    }

    .home {
        &__content {
            &__button {
                &__blue {
                    &__cover {
                        padding: 0.2rem!important;
                    }
                }
            } 
        }
    }
}