@import '../utils/mixins';
@import '../utils/variables';
@import '../utils/functions';

.program {
    background-color: $background-color-tertiairy;
    &__reconciliation {
        display: flex;
        &__text {
            padding: 1rem;
            text-align: center;
            align-self: center;
            h1 {
                font-size: $text-size-middle;
                padding: 1rem;
            }
            h3 {
                font-size: $text-size-very-very-small;
                padding: 1rem 0 2rem 0;
            }
        }
        &__image {
            width: 70%;
            height: 100%;
            object-fit: cover;
            img {
                height: auto;
                width: 100%;
            }
        }
        &__text {
            width: 30%;
            height: auto;
        }
    }
    &__social {
        padding-bottom: 10rem;
        &__icon {
            height: 10rem;
            display: flex;
            justify-content: center;
            a {
                align-self: center;
                padding: 0 5%;
            }
        }
        &__explore {
            display: flex;
            justify-content: center;
            &__mini {
                &__tablet {
                    display: none;
                }
            }
            &__info {
                z-index: 1!important;
                width: 100%!important;
                background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
            }
            &__title {
                h4 {
                    color: $text-color-second!important;
                }
            }
            &__card {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                text-align: center;
                width: 400px;
                height: 300px;
                &__image {
                    &__bottom {
                        object-position: center;
                    }
                    &__top {
                        object-position: top;
                    }
                }
                img{
                    width: 400px;
                    height: 300px;
                    object-fit: cover;
                    position: absolute;
                }
            }
        }
    }
}

.main__Consultation__container {
    .description {
        h2 {
            font-weight: 600;
        }
    }
}

.services {
    &__program {
        &__card {
            &__background {
                background-color: $background-color-tertiairy;
            }
        }
    }
}

#services {
    .main {
        &__consultation {
            &__background {
                background-color: $background-color-tertiairy!important;
            }
        }
    }
    .program {
        &__reconciliation {
            background-color: $background-color-primary;
            &__image {
                width: 50%;
                padding: 4rem 0 0 0;
                &__anxiete {
                    width: 70%!important;
                }
            }
            &__text {
                width: 50%;
                padding: 2rem;
                &__sub {
                    background-color: $background-color-primary;
                    width: 100%;
                    text-align: center;
                    p {
                        align-self: center;
                    }
                    padding: 0 0 1rem 0;
                }
                &__list {
                    &__left {
                        text-align: left;
                        padding: 2rem 1rem;
                        width: 30%;
                    }
                }
            }
        }
    }
    .depression {
        .main {
            &__consultation {
                &__background {
                    background-color: $background-color-primary!important;
                }
            }
        }
    }

    .anxiete {
        background-color: $background-color-second!important;
        color: $text-color-second;
        .program__reconciliation__text__sub, 
        .program__reconciliation,
        .program__reconciliation__text__sub {
            background-color: transparent!important;
        }
        .main {
            &__consultation {
                &__background {
                    background-color: $background-color-second!important;
                }
            }
        }
    }

    .negative {
        background-color: $background-color-primary;
        .program {
            &__reconciliation {
                &__image {
                    &__anxiete {
                        &__cover {
                            &__left {
                                width: 65% !important;
                                img {
                                    height: 100vh!important;
                                    object-fit: cover!important;
                                    width: 100%;                                
                                }
                            }
                        }
                        &__text {
                            &__sub {
                                width: 100vw!important;
                                img {
                                    height: 85vh;
                                    object-fit: cover;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                &__text {
                    &__anxiete {
                        &__text {
                            &__right {
                                width: 35%;
                                align-self: center!important;
                            }
                        }
                    }
                }
            }
        }     
        .main {
            &__consultation {
                &__background {
                    background: $background-color-primary!important;
                }
            }
        }
    }

    
} 

.depression {
    background-color: $background-color-primary!important;
}

#program__depression {
    display: $background-color-firth!important;
}

#program {
    &__anxiete {
        .program {
            &__reconciliation {
                &__image {
                    img {
                        height: 90vh;
                        width: 100%;
                        object-fit: cover;
                    }
                } 
            }
        }
    }
    &__mental {
        &__health {
            background-color: $background-color-tertiairy!important;
            .services__program__card__background,
            .mental__health,
            .program__reconciliation__text__sub {
                background-color: $background-color-tertiairy!important;
            }
            .program {
                &__reconciliation {
                    background-color: $background-color-firth!important;
                    &__image {
                        &__mentalhealth {
                            width: 60%!important;
                            img {
                                width: 100%;
                                height: 95vh!important;
                                object-fit: cover;
                            }
                        }
                    }
                    &__text {
                        &__list {
                            &__left {
                                width: 40%!important;
                                padding: 2rem 2rem!important;
                            }
                        }
                    }
                }
            }     
            .main__Consultation__container {
                top: 8.5rem;
            }
        }
    }
    &__fear {
        .program {
            &__reconciliation {
                &__text {
                    &__list {
                        &__left {
                            width: 45%;
                            padding: 2rem;
                            align-self: center;
                        }
                    }
                }
                &__image {
                    img {
                        height: 110vh;
                        width: 100%;
                        object-fit: cover;
                    }
                    &__cover {
                        &__sub {
                            width: 100vw!important;
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        &__image {
            &__anxiete {
                width: 55%;
            } 
        }
        .main__consultation__background {
            background-color: $background-color-primary!important;
        }       
        .main__Consultation__container {
            top: 8rem;
        }
    }
    &__frustration {
        background-color: $background-color-second!important;
        color: $text-color-second;
        .negative {
            background-color: $background-color-second!important;
            color: $text-color-second;
        }
        .program__reconciliation, .program__reconciliation__text__sub {
            background-color: $background-color-firth;
        }
        .program__reconciliation__text__list__left {
            width: 50%;
        }
        .program__reconciliation__image__mentalhealth {
            width: 50%;
            height: 100vh;
            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
        .main__Consultation__container {
            top: 8rem;
        }
        .main__consultation__background {
            background-color: $background-color-second!important;
        }
    }
    &__depression {
        background-color: $background-color-primary!important;
        .our {
            &__team {
                &__section {
                    padding: 10rem 0 0 0;
                }
            }
        }
    }
    &__negative {
        .main__Consultation__container {
            top: 8.5rem;
        }
    }
}

.section {
    &__before {
        &__footer {
            width: 100vw!important;
            &__cover {
                background-color: $background-color-second;
            }
            img {
                width: 100%;
                object-fit: cover;
            }
            .home__section__mission {
                padding-bottom: 0;
            }
        }
    }
    &__program {
        background-color: $background-color-tertiairy!important;
    }
}

#program__anxiete,
#program__negative,
#program__fear,
#program__mental__health,
#program__frustration {
    padding: 10% 0 !important;
}

#program__anxiete {
    .main__Consultation__container {
        top: 8.5rem;
    }
}

#program__frustration {
    .program__reconciliation__image__mentalhealth {
        width: 65%;
    }
}

#program__section__before__footer {
    padding: 10% 0 0 0;
}

//=======================================  Laptop ======================================//
@include Laptop {
    .program {
        &__reconciliation {
            &__text {
                padding: 0 2rem;
                &__sub {
                    padding: 2rem 0!important;
                }
            }
            &__image {
                padding: 0;
                height: 100vh;
                &__anxiete {
                    align-self: center;
                    width: 60%!important;
                }
            }
        }
    }

    .anxiete {
        padding: 15% 0 0 0;
        &__laptop {
            padding: 0;
        }
        .program {
            &__reconciliation {
                &__text {
                    &__list {
                        &__left {
                            width: 40%!important;
                        }
                    }
                    &__sub {
                        padding: 0!important;
                        p{
                            padding: 1rem;
                        } 
                    }
                }
                &__image {
                    &__anxiete {
                        width: 60%!important;
                    }
                }
            }
        }
    }

    #services {
        .program {
            &__reconciliation {
                &__image {
                    &__anxiete {
                        width: 60%!important;
                        img {
                            width: 100%!important;
                        }
                    }
                    &__fear {
                        width: 100vw!important;
                        img {
                            width: 100%!important;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
        .negative {
            .program {
                &__reconciliation {
                    &__image {
                        &__anxiete {
                            &__cover {
                                &__left {
                                    width: 55%!important;
                                }
                            }
                        }
                    }
                    &__text {
                        &__anxiete {
                            &__text {
                                &__right {
                                    width: 45%!important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    .negative,
    .mental__health,
    .fear,
    .frustration__laptop,
    .before__footer__laptop {
        .home {
            &__section {
                &__mission {
                    padding: 15% 0 0 0;
                }
            }
        }
    }
    
    .fear {
        .services__program__card__background {
            background-color: $background-color-primary;
        }
    }

    .fear {
        .services__program__card__background {
            padding: 0 0 9rem 0;
        }
    }

    #program {
        &__frustration{
            &__program {
                &__reconciliation {
                    &__image {
                        &__mentalhealth {
                            img {
                                width: 100%!important;
                            }
                        }
                    }
                }
            }
        }
    } 
}

//=======================================  Big Tablet ======================================//
@include bigTablet {
    #program__depression,
    #program__anxiete,
    #program__negative,
    #program__fear,
    #program__mental__health {
        padding: 40% 0 0 0 !important;
    }

    #services {
        padding: 20% 0 0 0;
    }

    .program__social__explore {
        display: none;
    }
    .program__social__explore__mini__tablet {
        display: flex!important;
    }

    #program__frustration {
        padding: 20% 0 0 0 !important;
    }

    #program__depression {
        .our__team__section {
            padding: 0;
        }
    }
    .program {
        &__reconciliation {
            &__image {
                img {
                    height: 70vh!important;
                    object-fit: cover;
                }
                &__anxiete {
                    img {
                        width: 100% !important;
                        height: 60vh!important;
                        object-fit: cover;
                    }
                    &__tablet {
                        img {
                            width: 100%!important;
                            height: 95vh!important;
                        }
                    }
                }
            } 
            &__text {
                &__list {
                    &__left {
                        &__tablet {
                            width: 50%!important;
                        }
                    }
                }
            }
        }
    }
    
    #program {
        &__anxiete {
            padding: 25% 0 0 0;
            .main__Consultation__container {
                top: 2.5rem;
            }
        } 
        &__mental {
            &__health {
                padding: 40% 0 40% 0!important;
                .main__Consultation__container {
                    top: 17rem!important;
                }
                .program {
                    &__reconciliation {
                        &__image {
                            &__mentalhealth {
                                img {
                                    width: 100%;
                                    height: 120vh!important;
                                    object-fit: cover;
                                }
                            }
                            &__anxiete {
                                &__text {
                                    &__sub {
                                        img {
                                            height: 90vh!important;
                                            object-position: 0%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }    
            }
        }
        &__frustration {
            .program__reconciliation {
                &__image {
                    img {
                        height: 120vh !important;
                    }
                    &__anxiete {
                        &__text {
                            &__sub {
                                img {
                                    height: 75vh!important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .fear {
        padding: 20% 0 80% 0 !important;
    }

    section {
        &__program {
            .main__Consultation__container {
                top: 2.5rem!important;
            }
        }
        .negative {
            padding: 0% 0 40% 0!important;
            .main__Consultation__container {
                top: 2rem!important;
            }
        }
    }

    .section__before__footer{
        .about__page__mission__title {
            padding-top: 40%!important;
        }
    }

    .negative {
        .about__page__mission__title {
            padding:0!important;
        }
    }

    .frustation {
        .main__Consultation {
            background-color: $background-color-primary!important;
        }
    }

    .mission-frist {
        padding: 0!important;
    }

    .section__before__footer {
        .about {
            &__page {
                &__mission {
                    &__title {
                        padding-top: 40% !important;
                    }
                }
            }
        }
    }

    #program__fear {
        padding: 40% 0 80% 0!important;
        .program__reconciliation__image {
            &__anxiete {
                img {
                    height: 140vh!important;
                }
            }
            &__cover {
                &__sub {
                    img {
                        height: 80vh!important;
                    }   
                }
            }
            
        }
        .main__Consultation__container {
            top: 29rem;
        }
    }

    #services {
        .negative {
            .program {
                &__reconciliation {
                    &__image {
                        &__anxiete {
                            &__cover {
                                &__left { 
                                    img {
                                        height: 100vh !important;
                                    }
                                }
                            }
                        }
                    }
                    &__text {
                        &__anxiete {
                            &__text {
                                &__right {
                                    width: 40%!important;
                                }
                            }
                        }
                    }
                }
            }
            .main__Consultation__container {
                top: 2.5rem!important;
            }
        }
    }
}

//=======================================  Mini Tablet ======================================//
@include tablet {
    #program__depression,
    #program__anxiete,
    #program__negative,
    #program__fear,
    #program__mental__health {
        padding: 30% 0 0 0 !important;
    }
    

    #program__frustration {
        padding: 30% 0 0 0 !important;
        .program__reconciliation__image__mentalhealth {
            img {
                object-fit: cover;
                width: 100%;
                height: 120vh!important;
                object-position: 40%;
            }
        }
        .program__reconciliation__image__anxiete {
            width: 50%!important;
        }
        .main__Consultation__container {
            top: 2rem!important;
        }
    }

    .program {
        &__social {
            &__explore {
                display: none;
                &__mini {
                    &__tablet {
                        display: flex;
                    }
                }
            }
        }
    }

    #services {
        .program__reconciliation__image {
            width: 50%!important;
        }
        .negative {
            padding: 3rem 0 8rem 0;
            .program {
                &__reconciliation {
                    &__image {
                        &__anxiete {
                            &__cover {
                                &__left {
                                    width: 50%!important;
                                    img {
                                        height: 100vh !important;
                                        object-fit: cover !important;
                                        padding-top: 50%;
                                        width: 100%!important;
                                        object-position: 60%;
                                    }
                                }
                            }
                        }
                    }
                    &__text {
                        &__anxiete {
                            &__text {
                                &__right {
                                    align-self: center !important;
                                    width: 50%!important;
                                }
                            }
                        }
                    }
                    &__image {
                        &__anxiete {
                            &__text {
                                &__sub {
                                    img {
                                        object-fit: cover;
                                        height: 60vh!important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .about__page__mission__title {
            padding: 30% 0 0 0!important;
        }
        .home__section__mission {
            h1, p {
                font-size: $text-size-middle!important;
            }
        }
        .program__reconciliation__image__anxiete {
            width: 50%!important;
        }
    }

    #program__depression {
        .about__page__mission__title {
            padding: 0!important;
        }
        .our__team__section {
            padding: 10rem 0 0 0;
        }
        .program__reconciliation {
            &__image {
            padding: 0rem 0;
            width: 50%!important;
            object-position: -3rem;
                img {
                    height: 95vh !important;
                    object-fit: cover;
                }
            }
            &__text {
                width: 50%;
                padding: 2rem;            
            }
        }
    }
    #program__anxiete {
        .program__reconciliation {
            &__image {
            padding: 0rem 0;
            width: 50%!important;
                &__anxiete {
                    &__tablet {
                        img {
                            width: 100% !important;
                            height: 130vh !important;
                        }
                    }
                }
            }
            &__text {
                &__list {
                    &__left {
                        &__tablet {
                            width: 50%!important;
                            padding: 2rem 1rem;    
                        }
                    }
                }       
            }
        }
        .main__Consultation__container {
            top: 2.5rem!important;
        }
    }

    #program__mental__health {
        .program__reconciliation__image__mentalhealth {
            img {
                width: 100%;
                height: 135vh !important;
                object-fit: cover;
            }
        }
        .main__Consultation__container {
            top: 2.5rem!important;
        }
    } 

    #program__negative {
        .main__Consultation__container {
            top: 2.5rem!important;
        }
    }

    #program__fear {
        .program {
            &__reconciliation {
                &__image {
                    &__anxiete {
                        img {
                            width: 100% !important;
                            height: 145vh !important;
                            object-fit: cover;
                            object-position: 60%;            
                        }
                    }
                    &__cover {
                        &__sub {
                            img {
                                height: 40vh!important;
                                width: 100vw!important;
                            }
                        }
                    }
                }
                &__text {
                    &__list {
                        &__left {
                            width: 50%;
                            padding: 2rem;
                            align-self: center;                        
                        }
                    }
                }
            }
        } 
        .main__Consultation__container {
            top: 2rem!important;
        }
    }



      .program {
        &__reconciliation {
            &__image {
                width: 50%!important;
                img {
                    height: 50vh!important;
                    object-fit: cover;
                }
                &__anxiete {
                    img {
                        width: 100% !important;
                        height: 60vh!important;
                        object-fit: cover;
                    }
                    &__tablet {
                        img {
                            width: 100%!important;
                            height: 100vh!important;
                        }
                    }
                }
            } 
            &__text {
                &__list {
                    &__left {
                        &__tablet {
                            width: 50%!important;
                        }
                    }
                }
            }
        }
    }

    
}

//=======================================  Mobile ======================================//
@include mobile {
    .program {
        &__reconciliation {
            flex-direction: column;
            &__text {
                width: 100vw;
                h1 {
                    font-size: $card-font-size-title!important;
                }
            }
            &__image {
                width: 100vw;
            }
        }
        &__social {
            padding-bottom: 0!important;
            &__explore {
                width: 100% !important;
                align-items: center;
                &__info {
                    width: 100%!important;
                }
                &__card {
                    width: 100%!important;
                    img {
                        width: 100%!important;
                    }
                }
            }
        }
    }

    #services {
        display: flex;
        flex-direction: column;
        #program__depression {
            display: flex;
            flex-direction: column;
            .program__reconciliation__image {
                padding: 0;
                width: 100%;
            }
            .program__reconciliation__text {
                width: 100%;
            }
        }
        #program__anxiete {
            display: flex;
            flex-direction: column;
            padding: 50% 0 0 0!important;
            .program__reconciliation__text__list__left, 
            .program__reconciliation__text__list__left__tablet {
                width: 100%;
                text-align: center;
            }
            .program__reconciliation__image__anxiete {
                height: 70vh;
            }
            .program__reconciliation__text__sub {
                padding: 0;
            }
            .program__reconciliation__image {
                width: 100vw!important;
                img {
                    height: 50vh!important;
                }
            }
            .main__Consultation__container {
                top: 3.5rem;
            }
        }
        #program__negative {
            display: flex;
            flex-direction: column;
            padding: 80% 0 0 0!important;
            .program__reconciliation__image__anxiete__cover__left {
                width: 100vw!important;
                img {
                    height: 70vh!important;
                }
            }
            .program__reconciliation__text__anxiete__text__right {
                width: 100%;
            }
            .program__reconciliation__image__anxiete__text__sub {
                img {
                    height: 40vh;
                }
            } 
            .main__Consultation__container {
                top: 3.5rem;
            }
        }
        #program__mental__health {
            display: flex;
            flex-direction: column;
            padding: 80% 0 0 0!important;
            .negative {
                .program__reconciliation__text__anxiete__text__right {
                    width: 100vw!important;
                }
                .program__reconciliation__image__anxiete__text__sub {
                    height: 50vh!important;
                }
            } 
            .program__reconciliation__image__mentalhealth {
                width: 100vw!important; 
                img {
                    height: 50vh!important;
                }
            }
            .main__Consultation__container {
                top: 4rem;
            }
        }
        #program__fear {
            display: flex;
            flex-direction: column;
            padding: 80% 0 0 0!important;
            .program__reconciliation__image__anxiete {
                width: 100vw!important;
            }
            .program__reconciliation__text__list__left {
                width: 100vw;
            }
            .program__reconciliation__image__cover__sub {
                width: 100vw!important;
                img {
                    height: 30vh;
                }
            }
            .main__Consultation__container {
                top: 2.5rem!important;
            }
        }
        #program__frustration {
            display: flex;
            padding: 80% 0 0 0!important;
            flex-direction: column;
            .negative {
                .program__reconciliation__text__anxiete__text__right {
                    width: 100%;
                }
            }
            .program__reconciliation__image__mentalhealth {
                width: 100vw;
            }
            .main__Consultation__container {
                top: 4rem;
            }
        }
        #program__section__before__footer {
            display: flex;
            flex-direction: column;
            padding: 80% 0 0 0!important;
        }

    }
}

//=======================================  Mobile ======================================//
@include miniMobile {
    .program {
        &__reconciliation {
            flex-direction: column;
            &__text {
                width: 100vw;
                h1 {
                    font-size: $card-font-size-title!important;
                }
            }
            &__image {
                width: 100vw;
            }
        }
        &__social {
            padding-bottom: 0!important;
            &__explore {
                width: 100% !important;
                align-items: center;
                &__info {
                    width: 100%!important;
                }
                &__card {
                    width: 100%!important;
                    img {
                        width: 100%!important;
                    }
                }
            }
        }
    }

    #services {
        display: flex;
        flex-direction: column;
        #program__depression {
            display: flex;
            flex-direction: column;
            .program__reconciliation__image {
                padding: 0;
                width: 100%;
            }
            .program__reconciliation__text {
                width: 100%;
            }
        }
        #program__anxiete {
            display: flex;
            flex-direction: column;
            padding: 70% 0 0 0!important;
            .program__reconciliation__text__list__left, 
            .program__reconciliation__text__list__left__tablet {
                width: 100%;
                text-align: center;
            }
            .program__reconciliation__image__anxiete {
                height: 70vh;
            }
            .program__reconciliation__text__sub {
                padding: 0;
            }
            .program__reconciliation__image {
                width: 100vw!important;
                img {
                    height: 50vh!important;
                }
            }
            .main__Consultation__container {
                top: 3.5rem;
            }
        }
        #program__negative {
            display: flex;
            flex-direction: column;
            padding: 80% 0 0 0!important;
            .program__reconciliation__image__anxiete__cover__left {
                width: 100vw!important;
                img {
                    height: 70vh!important;
                }
            }
            .program__reconciliation__text__anxiete__text__right {
                width: 100%;
            }
            .program__reconciliation__image__anxiete__text__sub {
                img {
                    height: 40vh;
                }
            } 
            .main__Consultation__container {
                top: 3.5rem;
            }
        }
        #program__mental__health {
            display: flex;
            flex-direction: column;
            padding: 80% 0 0 0!important;
            .negative {
                .program__reconciliation__text__anxiete__text__right {
                    width: 100vw!important;
                }
                .program__reconciliation__image__anxiete__text__sub {
                    height: 50vh!important;
                }
            } 
            .program__reconciliation__image__mentalhealth {
                width: 100vw!important; 
                img {
                    height: 50vh!important;
                }
            }
            .main__Consultation__container {
                top: 4rem;
            }
        }
        #program__fear {
            display: flex;
            flex-direction: column;
            padding: 80% 0 0 0!important;
            .program__reconciliation__image__anxiete {
                width: 100vw!important;
            }
            .program__reconciliation__text__list__left {
                width: 100vw;
            }
            .program__reconciliation__image__cover__sub {
                width: 100vw!important;
                img {
                    height: 30vh;
                }
            }
            .main__Consultation__container {
                top: 2.5rem!important;
            }
        }
        #program__frustration {
            display: flex;
            padding: 80% 0 0 0!important;
            flex-direction: column;
            .negative {
                .program__reconciliation__text__anxiete__text__right {
                    width: 100%;
                }
            }
            .program__reconciliation__image__mentalhealth {
                width: 100vw;
            }
            .main__Consultation__container {
                top: 4rem;
            }
        }
        #program__section__before__footer {
            display: flex;
            flex-direction: column;
            padding: 80% 0 0 0!important;
        }

    }
}

