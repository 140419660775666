@import '../utils/mixins';
@import '../utils/variables';
@import '../utils/functions';

.description {
    &__input {
        margin: 0!important;
    }
}

input {
    width: 100%!important;
}