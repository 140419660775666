@import '../utils/mixins';
@import '../utils/variables';
@import '../utils/functions';


header {
    position: fixed!important;
    width: 100%!important;
    z-index: 999999999999!important;
    padding: 0 2%!important;
    background-color: white!important;
}
nav {
    padding: 0 2%;
}

.white {
    background: $background-color-primary;
}

.sticky__header {
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99999;
}

.hamburger {
    display: none;
}


//=======================================  Big Tablet ======================================//
@include bigTablet {
    .navigation {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: $background-color-primary;
        display: none;
    }
    
    .menu {
        width: 15rem;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 200;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 30px;
    }

    .show__menu {
        display: block;
    }

    .hamburger {
        display: block!important;
    }
}



//=======================================  Mini Tablet ======================================//
@include tablet {
    header {
        padding: 0!important;
        nav {
            padding:0!important;
            .logo {
                height: 100px;
                img {
                    width: 100%;
                    padding: 10%;
                }
            }
        
            .mobile__menu {
                display: flex;
            }
        }
    }
    .navigation {  
        width: 100vw!important;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: $background-color-primary;
        display: none;
    }
    
    .menu, ul {
        width: 15rem;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 200;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 30px;
    }

    ul {
        flex-direction: column;
        background-color: white;
        width: 100vw;
        height: 100vh;
        padding: 0!important;
    }

    .show__menu {
        display: block;
    }

    .hamburger {
        display: block!important;
        padding-right: 10%!important;
        svg {
            width: 40px;
            height: 40px;        
        }
    }

    nav {
        width: 100vw!important;
        padding: 1% 1%!important;
        .logo, .hamburger {
            z-index: 9999999999999;
        }
    }
}


//=======================================  Mini Tablet ======================================//
@include mobile {
    header {
        padding: 0!important;
        nav {
            padding:0!important;
            .logo {
                height: 100px;
                img {
                    width: 100%;
                    padding: 10%;
                }
            }
        
            .mobile__menu {
                display: flex;
            }
        }
    }
    .navigation {  
        width: 100vw!important;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: $background-color-primary;
        display: none;
    }
    
    .menu, ul {
        width: 15rem;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 200;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 30px;
    }

    ul {
        flex-direction: column;
        background-color: white;
        width: 100vw;
        height: 100vh;
        padding: 0!important;
    }

    .show__menu {
        display: block;
    }

    .hamburger {
        display: block!important;
        padding-right: 10%!important;
        svg {
            width: 40px;
            height: 40px;        
        }
    }

    nav {
        width: 100vw!important;
        padding: 1% 1%!important;
        .logo, .hamburger {
            z-index: 9999999999999;
        }
    }
}