@import '../utils/mixins';
@import '../utils/variables';
@import '../utils/functions';

//===================================== Laptop =====================================//
@include Laptop {
    .home {
        &__cover {
            h1 {
                font-size: $text-size-big!important;
            }
        }
    }

    .cards__blue__background__padding {
        padding: 3rem 0!important;
    }
   
    .program {
        &__width {
            &__laptop {
                img {
                    width: 65%!important;
                }
                
                &__text {
                    width: 35%!important;
                }
            }
        }
        &__reconciliation {
            &__text {
                width: 35%;
            }
            &__image {
                width: 65%!important;
            }
        }
    }

    .testimonial {
        .liatestimons {
            padding: 3rem 0;
        }
    }

    .main {
        &__Consultation {
            &__container {
                font-weight: 400;
            }
        }
    }

    .mission {
        &-thrid {
            &-terms {
                &-navigation {
                    left: 0;
                    top: 0;
                    ul {
                        padding: 0;
                    }
                }
            }
            &-policy {
                bottom: 0;
            }
        }
    }
}

//=======================================  Mobile ======================================//
@include mobile {
    .home {
        &__banner {
            height: 100vh!important;
        }
        &__content {
            &__title {
                h1 {
                    font-size: $button-font-size-small!important;
                    padding-bottom: 1rem;
                }
            }
            &__slogan {
                p {
                    font-size: $button-font-very-very-small!important;
                    padding-bottom: 1rem;
                }
            }
            &__cover {
                &__infos {
                    &__container {
                        width: 70%!important;
                        top: 65%!important;
                        padding: 0!important;
                        left: 0;
                    }
                }
            }
            &__image {
                height: 100vh!important;
                object-fit: cover!important;
            }
        }
    }
}

