@import '../utils/mixins';
@import '../utils/variables';
@import '../utils/functions';

.card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    &__icon {
        width: 75px;
        height: 75px;
        border-radius: 50%;
    }
    &__section {
        text-align: center;
        padding: 1rem 0;
        h2 {
            font-size: $card-font-size-title-middle!important;
            font-weight: bold!important;
        }
        p {
            font-size: $card-font-size-title-middle!important;
            width: 300px;
        }
        &__icons {
            padding: 4rem!important;
        }
    }
    &__blue {
        &__content {
            display: flex;
            flex-direction: row;
            gap: 3rem;
            &__title {
                padding: 0!important;
            }
        }
        &__column {
            flex-direction: column!important;
        }
    }
    &__image {
        width: 250px;
        height: 225px;
        object-fit: cover;
    }
    &__width {
        width: 250px;
    }
}

.cards {
    display: flex;
    background: $background-color-primary;
    justify-content: center;
    gap: 0rem;
    padding: 0 2rem;
    flex-wrap: wrap;
    &__blue {
        &__background {
            background: $background-color-second;
        }
    }
    &__mobile {
        display: none;
    }
    &__tablet {
        display: none;
    }
}   

#ourteam {
    .profile {
        &__card {
            &__style {
                display: grid;
                gap: 3rem;
                font-weight: 400;
                font-style: italic;
                padding: 0;
                width: 300px!important;
                justify-content: space-between;
                flex-direction: column;
                text-align: center;
                align-items: center; 
                align-self: flex-start;
                color: $text-color-second;
                &__container {
                    padding: 5rem 0;
                }
                img {
                    object-fit: cover;
                    width: 300px;
                    height: 35vh;
                    object-position: top;
                }
                &__line {
                    display: flex;
                    padding: 2rem;
                }    
            }
        }
    }
}

//=======================================  Mini Mobile ======================================//
@include largeDesktop {
    .main__Consultation__container {
        .description__input {
            width: 100%!important;
            justify-content: space-around; 
            .message {
                width: 45%;
                .input {
                    width: 100%;
                }
                button {
                    width: 100%!important;
                }
            }
            .infos {
                width: 45%;
            }        
        }
    }
}

//===================================== Laptop =====================================//
@include Laptop {
    .main {
        &__Consultation {
            &__container {
                h2 {
                    font-weight: 500!important;
                }
            }
        }
    }    
}

//===================================== Big Tablet =====================================//
@include bigTablet {
    .cards {
        &__tablet {
            display: block;
        }
        &__desktop {
            display: none;
        }
    }
}

//===================================== Mini Tablet =====================================//
@include tablet {
    .cards {
        &__tablet {
            display: flex;
        }
        &__desktop {
            display: none;
        }
    }
}


//=======================================  Mobile ======================================//
@include mobile {
    .cards {
        flex-direction: column!important;
        &__mobile {
            display: flex!important;
        }
        &__desktop {
            display: none!important;
        }
        &__blue {
            &__background {
                padding: 1%;
            }
        }
    }

    #mini__tablet__programs__card__line__1,
    #mini__tablet__programs__card__line__2,
    #mini__tablet__programs__card__line__3,
    #mini__tablet__programs__card__line__4,
    #mini__tablet__programs__card__line__5 {
        display: none!important;
    }

    .card {
        flex-direction: column;
        &__blue {
            &__content {
                padding: 0.5rem 0;
            }
        }
        &__section {
            &__icons {
                padding: 5% !important;
            }
        }
    }

    .home {
        &__section {
            &__testimonial {
                &__card {
                    align-self: center;
                }
            }
        }
    }

    #ourteam {
        .profile {
            &__card {
                &__style {
                    align-self: center!important;
                    grid-template-columns: 1fr!important;
                    padding: 2rem 0!important;
                }
            }
        }
    }
}

//=======================================  Mini Mobile ======================================//
@include miniMobile {
    .home__section__cover__end {
        .card__section__icons {
            padding: 0% !important;
        }
    }
}