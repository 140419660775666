@import '../utils/mixins';
@import '../utils/variables';
@import '../utils/functions';

.contact{
    &__cover {
        width: 60%!important;
        img{
            padding: 0!important;
        } 
        
    }
    &__text {
        background-color: $background-color-second;
        color: $text-color-second;
        width: 40%!important;
        h3 {
            font-weight: 500;
        }
    }
}

.contact {
    &__map {
        &__container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            img {
                padding: 30% 40% 10% 40%;
            }
        }
        &__image {
            display: flex;
            justify-content: center;
            width: 80vw!important;
            align-content: center;
            align-self: center;
            padding: 0 0 20% 0;
        }
    }

}

//================================= Big Tablet =================================//
@include bigTablet {
    #contact__us {
        padding: 30% 0 0 0;
        a {
            font-size: $link-font-size-small;
        }
        #map {
            background-color: $background-color-second!important;
        }
        #contact {
            padding: 20% 0 0 0;
        }
    }  
}

//================================= Mini Tablet =================================//
@include tablet {
    #contact__us {
        padding: 30% 0 0 0;
        a {
            font-size: $link-font-size-small;
        }
        #map {
            background-color: $background-color-second!important;
        }
        #contact {
            padding: 20% 0 0 0;
        }
    }  
} 

//================================= Mobile =================================//
@include mobile{
    #map {
        background-color: $background-color-second;
        .contact {
            &__container {
                display: flex;
                justify-content: center;
                flex-direction: column;
            }
            &__text {
                width: 100%!important;
                gap: 0.5rem;
                a {
                    font-size: $card-font-size-title-middle!important;
                }
            }
            &__map {
                &__container {
                    padding: 60% 0 0 0;
                    img {
                        padding: 90%;
                    }
                }
            }
            &__cover {
                img {
                    height: 100vh!important;
                }
            }
        }
    }
    .contact {
        &__map {
            &__container {
                img {
                    padding: 120% 40% 30% 40%!important;
                }
            }
        }
    }
}   
