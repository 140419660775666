@import '../utils/mixins';
@import '../utils/variables';
@import '../utils/functions';

.mission{
    &-thrid{
        background-color: $background-color-second;
        padding-top: 3rem;
        color: $background-color-primary;
        font-family:Helvetica;
        &-terms{
            display: flex;
            justify-content: space-around;
            &-logo{
                margin-top: 6rem;
                width: 15rem;
                height: 16rem;
                background: $background-color-primary;
                &-symbole{
                    img{
                        width: 14rem;
                        padding-left: 1rem;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                    }
                }
                &-page{
                    background-color: $background-color-primary;
                    display: flex;
                    justify-content: space-around;
                    img{
                        height: 2rem;
                        width: 2rem;
                    }
                }
            }
            &-navigation{
                position: relative;
                left: 0;
                top: 0;
                ul{
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    line-height: 3rem;
                    color:$background-color-primary;
                    text-decoration: none;
                    a{
                        font-size: inherit;
                        font-weight: bold;
                        padding: 0.4rem 0;
                    }
                }
            }
            &-contact{
                display: flex;
                flex-direction: column;
                letter-spacing: 1px;
                text-align: center;
                line-height: 2rem;
                .links{
                    color: $text-color-second;
                    padding: 0.4rem;
                }
                h1,p{
                    padding: 0.4rem;
                }
                h1 {
                    font-weight: bold;
                }
                .adres{
                    padding-bottom: 1rem;
                }
                input{
                    height: 2.5rem;
                    width: 21rem;
                    text-align: center;
                    border-color: transparent;
                    font-size: 1rem;
                    color: $liBackgroundColorHoverSecondary;
                    background-color:$background-color-for-input-secondary ;
                    &:hover{
                        background-color: $liBackgroundColorHoverPrimary;
                        color: $liBackgroundColorHoverSecondary;
                    }
                }
                button{
                    height: 50px;
                    width: 200px;
                    font-size: $button-font-size-small;
                    border: 1px solid $button-background-color-primary;
                }
                button:before{
                    color: $button-text-color-primary;
                    background-color: $button-background-color-primary;
                } 
                button:hover:before{
                    border: 1px solid $button-text-color-primary;
                } 
                button:hover{
                    color:  $button-text-color-primary;
                }
                &-name{
                    margin-bottom: 1rem;
                    padding: 0.25rem 0!important;
                }
                &-email{
                    margin-bottom: 1rem;
                    padding: 0.25rem 0!important;
                }
                &-number{
                    margin-bottom: 1rem;
                    padding: 0.25rem 0!important;
                }
                &-message{
                    margin-bottom: 1rem;
                    padding: 0.25rem 0!important;
                    input{
                        height: 6rem;
                        width: 21rem;
                        text-align: start; 
                        padding-bottom: 2.5rem;
                        padding-left: 0.5rem;
                    }
                    
                }
            }
                
        
        }
        &-policy{
            display: flex;
            gap: 2rem;
            margin-left: 5rem;
            padding-bottom: 1rem;
            position: relative;
            bottom: 0;
            padding-left: 6rem;
            a{
                text-decoration: none;
                font-size: inherit;
                font-weight: bold;
            }
        }
    }
}

#footer__mobile {
    display: none;
}

//===================================== Tablet =====================================//
@include bigTablet {
    .mission-thrid-terms-logo {
        width: 20%!important;
        height: 100%!important;    
        img {
            width: 100%;
            padding: 1rem;

        }
        a {
            img {
                padding: 0.5rem!important;
            }
        }
    }
    .mission-thrid-terms-navigation {
        width: 20%;
        text-align-last: center;    
        ul {
            line-height: 2rem;
        }
    }
    .mission-thrid-policy {
        padding: 0.5rem 1rem;
        font-size: $button-font-size-small;
    }

    .mission-frist {
        padding: 0 1rem;
    }
}

//===================================== Laptop =====================================//
@include Laptop {
    .mission {
        &-thrid {
            &-terms {
                &-logo {
                    width: 15%;
                    height: 15%;   
                    img {
                        width: 100%;
                        padding: 0.4rem;
                    }             
                }
                &-navigation {
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}

//===================================== Mini Tablet =====================================//
@include tablet {
    .mission-thrid-terms-logo {
        width: 20%!important;
        height: 100%!important;    
        img {
            width: 100%;
            padding: 1rem;

        }
        a {
            img {
                padding: 0.5rem!important;
            }
        }
    }
    .mission-thrid-terms-navigation {
        width: 20%;
        text-align-last: center;    
        ul {
            width: 100%;
            background-color: $background-color-firth;
            line-height: 2rem;
            height: 31vh;
        }
    }
    .mission-thrid-policy {
        padding: 0.5rem 1rem;
        font-size: $button-font-size-small;
    }

    .mission-frist {
        padding: 0 1rem;
    }
}

//=======================================  Mobile ======================================//
@include mobile {
    #footer__desktop {
        display: none!important;
    }
    #footer__mobile{
        display: grid;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .mission {
        display: flex;
        &-thrid {
            display: flex;
            gap: 2%;
            &-terms {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
                &-logo {
                    width: 35%;
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    align-items: center;
                    img {
                        width: 100%;
                        padding: 0.4rem;
                        object-fit: contain;
                    }
                }
                &__symbole {
                    display: flex;
                }
                &-navigation {
                    width: 100%!important;
                    left: 0;
                    display: flex;
                    top: 0!important;
                    justify-content: center;
                    height: 50vh!important;
                    ul {
                        width: 100%!important;
                        line-height: 2rem;
                        padding: 0 2rem;
                        background-color: $background-color-second!important;
                        gap: 2rem;
                        margin: 0!important;
                        height: 100%!important;
                        a{
                            padding: 0;
                        }
                    }
                }
                &-contact {
                    z-index: 999;
                    padding: 0rem 0 15% 0;
                    .links, .link {
                        font-size: 18px!important;
                    }
                }
            }
            &-policy {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 100% 5% 0 5%;
                z-index: 9999;
                gap: 0.5rem;
                padding: 13% 0 0 0;
                font-size: $link-font-size-small;
                background: $background-color-second;
                a {
                    text-align: center;
                }
            }
        }
    }
}

//=======================================  Mini Mobile ======================================//
@include miniMobile {
    #footer__desktop {
        display: none!important;
    }
    #footer__mobile{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .testimonial {
        .home__section__testimonial {
            h1 {
                font-size: $card-font-size-title-big!important;
            }
        }
    }
    .mission-thrid-terms-navigation {
        top: 0!important;
    }
    .mission {
        display: flex;
        &-thrid {
            display: flex;
            gap: 2%;
            &-terms {
                &-navigation {
                    height: 50vh!important;
                    ul {
                        gap: 0rem;
                    }
                }
            }
        }
    }
}