@mixin microMobile{
    @media only screen and (max-width: 299px) {
        @content;
    } 
}

@mixin miniMobile{
    @media only screen and (max-width: 359px) {
        @content;
    } 
}

@mixin mobile{
    @media only screen and (max-width: 639px) {
        @content;
    } 
}

@mixin tablet {
    @media (min-width: 640px) and (max-width: 799px) {
        @content;
    }
}

@mixin bigTablet {
    @media (min-width: 800px) and (max-width: 1099px) {
        @content;
    } 
}

@mixin Laptop {
    @media (min-width: 1100px) and (max-width: 1439px) {
        @content;
    } 
}

@mixin Desktop {
    @media (min-width: 1440px) {
        @content;
    } 
}

@mixin largeDesktop {
    @media (min-width: 1940px) {
        @content;
    } 
}

@mixin min_aspect_ratio_16_9 {
    @media (min-aspect-ratio: 16/9) {
        @content;
    } 
}

@mixin displayFlex {
    display: flex;
    align-items: center;
}

@mixin displayFlexWrap {
    display: flex;
    flex-wrap: wrap;
}

@mixin displayGrid {
    display: grid;
    place-items: center;
}
@mixin buttonbig {
    width: 250px;
    height: 100px;
    font-size: $button-font-size-big;
}

@mixin displayFlexwrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

@mixin  displayFlexCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin displayFlexCenterwrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@mixin displayFlexCenterSpaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin displayFlexCenterSpaceBetweenwrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

@mixin directionColumnCenter {
    flex-direction: column;
    align-items: center;
}

@mixin microImageCircleLogo {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
}

@mixin microLinkCircleLogo {
    width: 20px;
    height: 20px;
    fill: #ccc;
}

@mixin button {
    @include displayFlexCenter;
    font: inherit;
    border: none;
    outline: none;
}

@mixin iconGridCenter {
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
}

@mixin microIconGridCenter {
    width: 21px;
    height: 21px;
    display: grid;
    place-items: center;
}






